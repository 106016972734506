import { compose, withHooks, withStores } from "enhancers"
import { Box, Button, Divider, Grid, Icon, IconButton, Modal, Notification, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { MuiIcon } from "components/common/MuiIcon"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import styled from "styled-components"
import { AppColor, backGroundColorGradient } from "theme/app-color"
import { employeeIsHr, gql, paths } from "utils/helper"
import { Field } from "../../claim-detail/form-field"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"
import LoadingModal from "components/LoadingModal"
import { INPUT_TYPE } from "constants/enums/input-type"
import { useHistory } from "react-router-dom"
const DetailStatusContainer = styled("div")`
  position: relative;
  /* background-color: ${AppColor["Primary/Line"]}; */
  background-color: ${AppColor["Primary/Line"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${AppColor["White / White"]};
  height: auto;
  padding: 16px;
`

const CloseButton = styled(IconButton)`
  color: ${AppColor["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${AppColor["White / White"]};
  border-radius: 8px;
  margin-top: 16px;
`

const HistoryDetailPreviewPage = (props: any) => (
  <>
    <LoadingModal isOpen={!props.isLoaded || props.imageLoading} />
    {props.isLoaded && (
      <>
        <Box style={{ backgroundColor: AppColor["Primary/Line"] }}>
          <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
            <DetailStatusContainer>
              <TopBox>
                <Typography variant="h2" color="White / White">
                  รายละเอียดการเบิก
                </Typography>
                <CloseButton onClick={props.handleClose}>
                  <MuiIcon name="Close" style={{ fontSize: "24px" }} />
                </CloseButton>
              </TopBox>
              <DetailBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ผู้เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.createdBy}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      ฝ่าย/สังกัด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.affiliation}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      กลุ่มพนักงาน
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.employeeGroup}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เบอร์โทร
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.phoneNumber}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      อีเมล
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.email}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      เลขอ้างอิง
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {props.claimId}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      วันที่เบิก
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
                <FlexBox mb="8px" minWidth={325} maxWidth={730} overflow="hidden">
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      สถานะ
                    </Typography>
                  </FrontBox>
                  <StepStatus claimStatus={props.claimStatus} workflows={props.workflows} canceled={props.canceled} />
                </FlexBox>
                <FlexBox>
                  <FrontBox mr="16px">
                    <Typography variant="body1" color="White / White">
                      แก้ไขล่าสุด
                    </Typography>
                  </FrontBox>
                  <Typography variant="body1" color="White / White">
                    {dayjs(props.updatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </FlexBox>
              </DetailBox>
              {props.claimStatus === EnumClaimRequestStatus.APPROVED && props.transferDate && (
                <LightBlueBox padding="8px 24px">
                  <Box display="flex" flexDirection="column" textAlign="center">
                    <Typography variant="body1" color="Primary/Line">
                      {`${props.mappingPaymentTypeMessage} ${props.requestAmount(props.claimRequest)} บาท`}
                    </Typography>
                    <Typography variant="body1" color="Primary/Line">
                      {`เข้าบัญชีพนักงานวันที่ ${dayjs(props.transferDate).format("DD/MM/YYYY")}`}
                    </Typography>
                  </Box>
                </LightBlueBox>
              )}
              {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
                <LightBlueBox padding="8px 16px">
                  <FlexBox>
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        เหตุผล:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.rejectReason || "-"}
                    </Typography>
                  </FlexBox>
                  <FlexBox mt="8px">
                    <FrontBox mr="8px">
                      <Typography variant="body1" color={AppColor["Primary/Line"]}>
                        หมายเหตุ:
                      </Typography>
                    </FrontBox>
                    <Typography variant="body1" color={AppColor["Primary/Line"]}>
                      {props.remark || "-"}
                    </Typography>
                  </FlexBox>
                </LightBlueBox>
              )}
            </DetailStatusContainer>
          </Box>
        </Box>
        <Box minHeight={200} minWidth={357} maxWidth={752} overflow="hidden" mx="auto">
          <ClaimDetail>
            <Box mt="8px">
              <ClaimCard
                title={props.title}
                type={props.type}
                name={props.name}
                approvedRequest={props.approvedCount}
                totalAmount={props.approvedAmount}
                icon={props.icon}
                iconColor={props.iconColor}
                chipTitleColor={props.chipTitleColor}
                chipBackgroundColor={props.chipBackgroundColor}
                fullSize
                displayOnly
              />
            </Box>
            <Title>
              <Typography variant="h3">ตรวจสอบรายละเอียด</Typography>
            </Title>

            <Grid container style={{ paddingTop: 16 }}>
              <Grid item xs={12} key="select-employee">
                <Typography variant="body1" color="Text/Gray Preview">
                  ผู้ขอเบิก
                </Typography>
                <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
              </Grid>
              {props.claimFor && props.claimFor !== "Self" && (
                <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
                  <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimFor.nameTh} />
                </Grid>
              )}
              {props.inputs?.map((input: any) => (
                <Grid
                  item
                  xs={12}
                  key={`${input.name}-4`}
                  style={{ paddingTop: input.type === INPUT_TYPE.SUM_AMOUNT ? 0 : 16 }}
                >
                  <Field
                    isPreview={true}
                    value={props.inputValues[input.name]}
                    component={input.type}
                    label={input.title}
                    name={input.name}
                    options={input.options}
                    unit={input.unit}
                    requester={props.requester}
                    hidden={input.hidden}
                  />
                </Grid>
              ))}
            </Grid>
            {props.documents.length > 0 && (
              <UploadDocumentCard
                documents={props.documents}
                values={props.documentValues}
                onChange={props.handleFilesChange}
                canDelete={false}
                isPreview={true}
                onLoading={(loading) => props.setImageLoading(loading)}
              />
            )}
          </ClaimDetail>
        </Box>
      </>
    )}
  </>
)

const mapPaymentType = (paymentType: any) => {
  switch (paymentType) {
    case "โอนเข้าบัญชีเงินเดือน":
      return "โอนเงิน"
    case "เงินสด":
      return "จ่ายเงินสด"
    case "เช็ค":
      return "จ่ายเช็ค"
    default:
      return "โอนเงิน"
  }
}

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetailPreview(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee {
          id
          firstName
          lastName
          department
          officialMailId
        }
        createdAt
        updatedAt
        createdBy
        referenceId
        rejectReason
        remark
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
      }
    }
  `,
}

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useParams, useQuery, useState, useEffect, useMutation, useMemo, useCallback, useUrlParam } = hooks
    const history = useHistory()

    const { id } = useParams()

    const { data, refetch, loading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
      fetchPolicy: "network-only",
    })

    const [imageLoading, setImageLoading] = useState(true)

    const employee = useMemo(() => data?.claimRequestHistoryDetailPreview.employee, [data])
    const handleClose = useCallback(() => {
      history.goBack()
    }, [history])

    const hasFile = useMemo(
      () => data?.claimRequestHistoryDetailPreview.info.documents.filter((doc: any) => doc.file) > 0,
      [data],
    )

    const requestAmount = useCallback((history: any) => {
      return history?.info?.values?.inputs[history.info.amountFieldName] || 0
    }, [])

    const latestWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetailPreview.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetailPreview.currentWorkflow,
      )
      return currentWorkflow?.workflow
    }, [data])

    const mappingPaymentTypeMessage = useMemo(() => {
      const paymentType = data?.claimRequestHistoryDetailPreview.info.values.inputs["payment"]
      return mapPaymentType(paymentType)
    }, [data?.claimRequestHistoryDetailPreview.info.values.inputs])

    const initialDocumentValues = useMemo(() => {
      let initialDocuments = {}
      data?.claimRequestHistoryDetailPreview.info.documents.forEach((doc: any) => {
        const docs = data?.claimRequestHistoryDetailPreview.info.values.documents[doc.name]
        initialDocuments = {
          ...initialDocuments,
          [doc.name]: docs || [],
        }
      })

      return initialDocuments
    }, [
      data?.claimRequestHistoryDetailPreview.info.documents,
      data?.claimRequestHistoryDetailPreview.info.values.documents,
    ])

    const [documentValues, setDocumentValues] = useState(initialDocumentValues)

    const lastWorkflowInputs = useMemo(
      () => latestWorkflow?.inputs || data?.claimRequestHistoryDetailPreview.info.inputs,
      [latestWorkflow, data],
    )

    useEffect(() => {
      setDocumentValues(initialDocumentValues)
    }, [initialDocumentValues])

    const [hasNoDocuments, setHasNoDocument] = useState(false)
    useEffect(() => {
      if (data) {
        const documentValues = data?.claimRequestHistoryDetailPreview.info.values.documents
        const documentHasValues = Object.keys(documentValues).filter((key) => documentValues[key].length > 0)
        console.log("test", documentValues, documentHasValues)
        if (documentHasValues.length === 0) {
          console.log("5555", documentHasValues)

          setImageLoading(false)
          setHasNoDocument(true)
        }
      }
    }, [data])

    const isLoaded = useMemo(() => !loading, [loading])

    return {
      handleClose,
      createdAt: data?.claimRequestHistoryDetailPreview.createdAt,
      updatedAt: data?.claimRequestHistoryDetailPreview.updatedAt,
      inputs: lastWorkflowInputs,
      documents: data?.claimRequestHistoryDetailPreview.info.documents || [],
      inputValues: data?.claimRequestHistoryDetailPreview.info.values.inputs,
      documentValues,
      claimId: data?.claimRequestHistoryDetailPreview.referenceId,
      createdBy:
        data?.claimRequestHistoryDetailPreview.employee.firstName +
        " " +
        data?.claimRequestHistoryDetailPreview.employee.lastName,
      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,
      requester: employee,

      workflows: data?.claimRequestHistoryDetailPreview.workflowSeq || [],
      hasFile,
      title: data?.claimRequestHistoryDetailPreview.info.title || "",
      subTitle: data?.claimRequestHistoryDetailPreview.info.subTitle,
      icon: data?.claimRequestHistoryDetailPreview.info.icon,
      iconColor: data?.claimRequestHistoryDetailPreview.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetailPreview.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetailPreview.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetailPreview.info.type,
      name: data?.claimRequestHistoryDetailPreview.info.name,
      approvedAmount: data?.claimRequestHistoryDetailPreview?.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetailPreview?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetailPreview.status,
      transferDate: data?.claimRequestHistoryDetailPreview.transferDate || null,
      rejectReason: data?.claimRequestHistoryDetailPreview.rejectReason || null,
      remark: data?.claimRequestHistoryDetailPreview.remark || null,

      claimFor: data?.claimRequestHistoryDetailPreview.info.claimFor,
      affiliation: data?.claimRequestHistoryDetailPreview.employee.department,
      email: data?.claimRequestHistoryDetailPreview.employee.officialMailId || "-",
      employeeGroup: "-",
      phoneNumber: "-",
      claimRequest: data?.claimRequestHistoryDetailPreview,
      requestAmount,

      mappingPaymentTypeMessage,
      isLoaded,

      imageLoading,
      setImageLoading,

      hasNoDocuments,
    }
  }),
)

export default enhancer(HistoryDetailPreviewPage)
